// Generated by Framer (508aa67)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ysn4ONrTn", "N5kS3HtOK", "LWTHH5SkM", "HP6w_wO8l", "j1seGNPoi", "MYpS_CCCy", "qrd8PZyoZ", "hVmqdRUHq", "Ga48329xN", "Mcg2sKjh8", "VOnrSAo0N", "oUVOUWf5l", "y9KbaFOO1", "vZRrZfqtV"];

const serializationHash = "framer-Kz31t"

const variantClassNames = {Ga48329xN: "framer-v-elb50z", HP6w_wO8l: "framer-v-103k43j", hVmqdRUHq: "framer-v-zam3oj", j1seGNPoi: "framer-v-4w1ilv", LWTHH5SkM: "framer-v-wucwjs", Mcg2sKjh8: "framer-v-1ho3i7o", MYpS_CCCy: "framer-v-uag32n", N5kS3HtOK: "framer-v-ipakhl", oUVOUWf5l: "framer-v-1l0vxxu", qrd8PZyoZ: "framer-v-15dx9ol", VOnrSAo0N: "framer-v-13zw3na", vZRrZfqtV: "framer-v-1yp07v8", y9KbaFOO1: "framer-v-7yhalv", ysn4ONrTn: "framer-v-1t7hdy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"2XL - 80px": "HP6w_wO8l", "2XS - 16px": "Mcg2sKjh8", "3XL - 96px": "LWTHH5SkM", "3XS - 12px": "VOnrSAo0N", "4XL - 120px": "N5kS3HtOK", "4XS - 8px": "oUVOUWf5l", "5XL - 160px": "ysn4ONrTn", "5XS - 4px": "y9KbaFOO1", "6XS - 2px": "vZRrZfqtV", "L - 48px": "MYpS_CCCy", "M - 40px": "qrd8PZyoZ", "S - 32px": "hVmqdRUHq", "XL - 64px": "j1seGNPoi", "XS - 24px": "Ga48329xN"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ysn4ONrTn"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ysn4ONrTn", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "HP6w_wO8l") return true
return false
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1t7hdy", className, classNames)} data-framer-name={"5XL - 160px"} layoutDependency={layoutDependency} layoutId={"ysn4ONrTn"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({Ga48329xN: {"data-framer-name": "XS - 24px"}, HP6w_wO8l: {"data-framer-name": "2XL - 80px"}, hVmqdRUHq: {"data-framer-name": "S - 32px"}, j1seGNPoi: {"data-framer-name": "XL - 64px"}, LWTHH5SkM: {"data-framer-name": "3XL - 96px"}, Mcg2sKjh8: {"data-framer-name": "2XS - 16px"}, MYpS_CCCy: {"data-framer-name": "L - 48px"}, N5kS3HtOK: {"data-framer-name": "4XL - 120px"}, oUVOUWf5l: {"data-framer-name": "4XS - 8px"}, qrd8PZyoZ: {"data-framer-name": "M - 40px"}, VOnrSAo0N: {"data-framer-name": "3XS - 12px"}, vZRrZfqtV: {"data-framer-name": "6XS - 2px"}, y9KbaFOO1: {"data-framer-name": "5XS - 4px"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-text-alignment": "center"}}><motion.br className={"trailing-break"}/></motion.p></React.Fragment>} className={"framer-1bxfrda"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"Q54xL6xIQ"} verticalAlignment={"top"} withExternalLayout/>)}</motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Kz31t.framer-1uzue26, .framer-Kz31t .framer-1uzue26 { display: block; }", ".framer-Kz31t.framer-1t7hdy { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 160px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 160px; }", ".framer-Kz31t .framer-1bxfrda { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Kz31t.framer-1t7hdy { gap: 0px; } .framer-Kz31t.framer-1t7hdy > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-Kz31t.framer-1t7hdy > :first-child { margin-top: 0px; } .framer-Kz31t.framer-1t7hdy > :last-child { margin-bottom: 0px; } }", ".framer-Kz31t.framer-v-ipakhl.framer-1t7hdy { height: 120px; width: 120px; }", ".framer-Kz31t.framer-v-wucwjs.framer-1t7hdy { height: 96px; width: 96px; }", ".framer-Kz31t.framer-v-103k43j.framer-1t7hdy { height: 80px; width: 80px; }", ".framer-Kz31t.framer-v-4w1ilv.framer-1t7hdy { height: 64px; width: 64px; }", ".framer-Kz31t.framer-v-uag32n.framer-1t7hdy { height: 48px; width: 48px; }", ".framer-Kz31t.framer-v-15dx9ol.framer-1t7hdy { height: 40px; width: 40px; }", ".framer-Kz31t.framer-v-zam3oj.framer-1t7hdy { height: 32px; width: 32px; }", ".framer-Kz31t.framer-v-elb50z.framer-1t7hdy { height: 24px; width: 24px; }", ".framer-Kz31t.framer-v-1ho3i7o.framer-1t7hdy { height: 16px; width: 16px; }", ".framer-Kz31t.framer-v-13zw3na.framer-1t7hdy { height: 12px; width: 12px; }", ".framer-Kz31t.framer-v-1l0vxxu.framer-1t7hdy { height: 8px; width: 8px; }", ".framer-Kz31t.framer-v-7yhalv.framer-1t7hdy { height: 4px; width: 4px; }", ".framer-Kz31t.framer-v-1yp07v8.framer-1t7hdy { height: 2px; width: 2px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 160
 * @framerIntrinsicWidth 160
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"N5kS3HtOK":{"layout":["fixed","fixed"]},"LWTHH5SkM":{"layout":["fixed","fixed"]},"HP6w_wO8l":{"layout":["fixed","fixed"]},"j1seGNPoi":{"layout":["fixed","fixed"]},"MYpS_CCCy":{"layout":["fixed","fixed"]},"qrd8PZyoZ":{"layout":["fixed","fixed"]},"hVmqdRUHq":{"layout":["fixed","fixed"]},"Ga48329xN":{"layout":["fixed","fixed"]},"Mcg2sKjh8":{"layout":["fixed","fixed"]},"VOnrSAo0N":{"layout":["fixed","fixed"]},"oUVOUWf5l":{"layout":["fixed","fixed"]},"y9KbaFOO1":{"layout":["fixed","fixed"]},"vZRrZfqtV":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerl4UQgwJcw: React.ComponentType<Props> = withCSS(Component, css, "framer-Kz31t") as typeof Component;
export default Framerl4UQgwJcw;

Framerl4UQgwJcw.displayName = "Spacing Block";

Framerl4UQgwJcw.defaultProps = {height: 160, width: 160};

addPropertyControls(Framerl4UQgwJcw, {variant: {options: ["ysn4ONrTn", "N5kS3HtOK", "LWTHH5SkM", "HP6w_wO8l", "j1seGNPoi", "MYpS_CCCy", "qrd8PZyoZ", "hVmqdRUHq", "Ga48329xN", "Mcg2sKjh8", "VOnrSAo0N", "oUVOUWf5l", "y9KbaFOO1", "vZRrZfqtV"], optionTitles: ["5XL - 160px", "4XL - 120px", "3XL - 96px", "2XL - 80px", "XL - 64px", "L - 48px", "M - 40px", "S - 32px", "XS - 24px", "2XS - 16px", "3XS - 12px", "4XS - 8px", "5XS - 4px", "6XS - 2px"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerl4UQgwJcw, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})